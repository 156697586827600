<template>
  <div class="app-view d-flex flex-column">
    <div class="app-view__header d-flex">
      <div class="col-md-4 col-lg-3 d-none d-md-flex align-items-center">
        <div class="app-view__header-wrapper">
          <h1 class="mb-0 app-view__name">{{ $t("name") }}</h1>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-9 d-flex align-items-center justify-content-between">
        <b-dropdown right :text="selectedLang">
          <b-dropdown-item @click="changeLang('en')">{{ languages[0].text }}</b-dropdown-item>
          <b-dropdown-item @click="changeLang('ru')">{{ languages[1].text }}</b-dropdown-item>
          <b-dropdown-item @click="changeLang('ua')">{{ languages[2].text }}</b-dropdown-item>
        </b-dropdown>
        <Slide right @openMenu="showOverlay = true" @closeMenu="showOverlay = false">
          <a href="https://gitbook.sshiling.com/projects/orbi-group-hotels-services" target="_blank" class="nav-link">
            {{ $t("link.projects") }}
          </a>
          <a href="https://metronic.sshiling.com/dashboard" target="_blank" class="nav-link">
            {{ $t("link.components") }}
          </a>
          <a href="https://gitbook.sshiling.com/" target="_blank" class="nav-link">{{ $t("link.gitbook") }}</a>
        </Slide>
      </div>
    </div>
    <div class="app-view__body d-flex align-items-center h-100">
      <div class="d-flex w-100">
        <div class="app-view__label d-none d-lg-block col-3">
          <span>{{ $t("about") }}</span>
        </div>
        <div class="app-view__content col-12 col-lg-9">
          <div class="app-view__description d-flex flex-column">
            <h1 class="app-view__title mb-0">{{ $t("title") }}</h1>
            <h3 class="my-10">{{ $t("tech_stack") }}</h3>
          </div>
          <div v-for="(set, index) in skillsSet" :key="index" class="d-flex align-items-start">
            <p class="app-view__section-title m-0">{{ $t(`set_title.${set.title}`) }}</p>
            <div class="d-flex flex-wrap">
              <b-badge
                v-for="(item, index) in set.skills"
                :key="index"
                class="mr-5 mb-5"
                :class="item.active ? 'badge-active' : 'badge-not-active'"
                pill
              >
                {{ item.skill }}
              </b-badge>
            </div>
          </div>
          <div class="d-flex flex-column flex-sm-row align-items-center mt-10">
            <a href="/Sergii%20Shilingov.%20Vue.js%20Developer.pdf" download class="btn btn-outline-warning">
              {{ $t("btn.download") }}
            </a>
            <span class="mx-10 my-5 my-lg-0">{{ $t("or") }}</span>
            <a
              href="https://drive.google.com/file/d/1tp78MV_eYCtTRo-A83hisG0t-RedrgoJ/view?usp=sharing"
              target="_blank"
              class="btn btn-outline-success"
            >
              {{ $t("btn.watch") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="app-view__footer row">
      <div class="col-lg-3"></div>
      <div class="d-flex flex-column align-items-center align-items-lg-start col-12 col-lg-9">
        <h3 class="mb-0 d-none d-lg-block">{{ $t("contact_me") }}</h3>
        <a href="mailto:s.shilingov@gmail.com" class="my-8">s.shilingov@gmail.com</a>
        <div class="app-view__footer-icons">
          <a href="https://www.linkedin.com/in/shilingov/" target="_blank">
            <i class="icon-2x icon-sm-3x la text-dark-50 socicon socicon-linkedin"></i>
          </a>
          <a href="https://t.me/sshiling" target="_blank">
            <i class="icon-2x icon-sm-3x la text-dark-50 socicon socicon-telegram mx-20 mx-sm-30"></i>
          </a>
          <a href="https://www.facebook.com/svshilingov" target="_blank">
            <i class="icon-2x icon-sm-3x la text-dark-50 socicon socicon-facebook"></i>
          </a>
        </div>
      </div>
    </div>
    <div v-show="showOverlay" class="app-view__navigation-overlay"></div>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  components: {
    Slide
  },

  data: () => ({
    selectedLang: null,
    showOverlay: false,
    languages: [
      { code: "en", text: "Eng" },
      { code: "ru", text: "Рус" },
      { code: "ua", text: "Укр" }
    ],
    skillsSet: [
      {
        title: "base",
        skills: [
          { skill: "HTML", active: true },
          { skill: "CSS", active: true },
          { skill: "SCSS", active: true },
          { skill: "TailwindCSS", active: false },
          { skill: "JavaScript (ES6+)", active: true },
          { skill: "TypeScript", active: false }
        ]
      },
      {
        title: "vue",
        skills: [
          { skill: "Vue.js", active: true },
          { skill: "Vuex", active: true },
          { skill: "Vue Router", active: true },
          { skill: "Vue-CLI", active: true },
          { skill: "Nuxt.js", active: false },
          { skill: "SSR", active: false },
          { skill: "PWA", active: false }
        ]
      },
      {
        title: "ui",
        skills: [
          { skill: "Quasar", active: true },
          { skill: "Bootstrap Vue", active: true },
          { skill: "Vuetify", active: false },
          { skill: "Metronic UI", active: true }
        ]
      },
      {
        title: "tests",
        skills: [
          { skill: "Jest", active: true },
          { skill: "Cypress", active: false },
          { skill: "Sentry", active: false },
          { skill: "BrowserStack", active: false }
        ]
      },
      {
        title: "docs",
        skills: [
          { skill: "Styleguidist", active: false },
          { skill: "Gitbook", active: true }
        ]
      },
      {
        title: "network",
        skills: [
          { skill: "HTTP (Axios)", active: true },
          { skill: "WebSockets (Socket.IO)", active: true }
        ]
      },
      {
        title: "ci",
        skills: [
          { skill: "Docker", active: true },
          { skill: "GitHub Actions", active: true },
          { skill: "Netlify", active: true }
        ]
      },
      {
        title: "git",
        skills: [
          { skill: "Git", active: true },
          { skill: "GitHub", active: true },
          { skill: "GitLab", active: true }
        ]
      },
      {
        title: "tasks",
        skills: [
          { skill: "Jira", active: true },
          { skill: "Bitrix24", active: true }
        ]
      },
      {
        title: "ide",
        skills: [
          { skill: "PhpStorm", active: true },
          { skill: "WebStorm", active: true }
        ]
      }
    ]
  }),

  beforeMount() {
    const initLangCode = localStorage.getItem("language");
    this.selectedLang = initLangCode
      ? this.languages.find((item) => item.code === initLangCode).text
      : this.languages[0].text;
  },

  methods: {
    changeLang(lang) {
      localStorage.setItem("language", lang);
      location.reload();
    }
  }
};
</script>

<style lang="scss">
*:focus {
  outline: none;
}

.app-view {
  flex: 1;
  padding: 0 20px;
  color: #ececec;
  background-color: #333646;

  .app-view__header {
    .bm-burger-button {
      top: 5px !important;
      right: 15px !important;

      &:hover {
        .bm-burger-bars {
          background-color: #ffa800;
        }
      }
    }

    .bm-burger-bars {
      background-color: white;
    }

    .line-style {
      height: 10%;
    }

    .bm-cross-button .bm-cross {
      background-color: #333646;
    }

    .bm-cross-button:hover .bm-cross {
      background-color: #ffa800;
    }

    .bm-menu {
      background-color: #f2f3f8;
    }
  }
}

.app-view__header {
  margin: 30px 0;
}

.app-view__footer {
  margin: 30px 0;
}

.app-view__name {
  white-space: nowrap;
}

.app-view__section-title {
  font-size: 14px;
  line-height: 22px;
  min-width: 80px;
}

.app-view__footer-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-view__title {
  font-size: 18px;
  line-height: 1.5;
}

.app-view__description-line {
  margin-bottom: 10px;
}

.socicon {
  cursor: pointer;

  &:hover {
    color: #ffa800 !important;
  }
}

.badge {
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
}

.badge-not-active {
  color: #a9a9a9;
}

.nav-link {
  color: #333646;
}

.app-view__navigation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 700;
}

@media (min-width: 576px) {
  .app-view {
    padding: 0 100px;
  }

  .app-view__header {
    margin-top: 50px;
  }

  .app-view__footer {
    margin-bottom: 50px;
  }
}

@media (min-width: 960px) {
  .app-view__title {
    margin-top: -5px;
    font-size: 32px;
    line-height: 1;
  }

  .app-view__section-title {
    line-height: 24px;
    min-width: 100px;
  }

  .badge {
    min-width: 60px;
    font-size: 14px;
  }

  .btn {
    width: unset;
  }
}
</style>
